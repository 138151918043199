<template>
  <HRow
      class="dynamic-row"
  >
    <HCol
      v-if="isColumnVisible(column)"
      v-for="(column, columnIndex) in columns"
      :key="columnIndex"
      :class="column.class"
      :cols="getColumnSize(column, 'cols')"
      :colsXs="getColumnSize(column, 'colsXs')"
      :colsSm="getColumnSize(column, 'colsSm')"
      :colsMd="getColumnSize(column, 'colsMd')"
      :colsLg="getColumnSize(column, 'colsLg')"
      :colsXl="getColumnSize(column, 'colsXl')"
    >
      <slot :name="column.slot" />
    </HCol>
  </HRow>
</template>

<script>
import _ from 'lodash'
import {
  HRow, HCol
} from '@happytal/bo-ui-library'

export default {
    name: 'DynamicRow',
    components: {
      HCol,
      HRow
    },
    props: {
      columns: {
        type: Array
      }
    },
    methods: {
        getColumnSize (column, type) {
          if (column.fixed) {
            return column[type]
          }
          const columnVisibleCount = this.getVisibleColumnCount()
          const columnSizeTotal = this.getColumnSizeTotal(type)
          const columnSizeLeft = this.getColumnSizeLeft(type)
          //console.log('columnVisibleCount', columnVisibleCount)
          //console.log('columnSizeTotal', columnSizeTotal)
          //console.log('columnSizeLeft', columnSizeLeft)
          return Math.floor(columnSizeLeft / columnVisibleCount + column[type])
        },
        isColumnVisible (column) {
          return column.visible ? column.visible() : 1
        },
        getVisibleColumnCount () {
          return _.filter(this.columns, (column) => !column.fixed && this.isColumnVisible(column)).length
        },
        getColumnSizeTotal (type) {
          return _.sumBy(this.columns, (column) => column[type])
        },
        getColumnSizeLeft (type) {
          return _.sumBy(this.columns, (column) => this.isColumnVisible(column) ? 0 : column[type])
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.dynamic-row {

}
</style>
