<template>
  <div id="residents-view">
    <div v-if="$breakpoint.smAndDown">

      <div class="header">
        <div class="title">Liste des résidents</div>
        <HBtn
          v-if="$aclChecker.some($acl, ['isEhpadManager', 'isHappytalSupport', 'isHappytalEmployee', 'isEhpadEmployee'])"
          styleType="secondary"
          icon="PlusIcon"
          @click="onAddResidentBtnClick"
        >Nouveau</HBtn>
      </div>

      <h-search-field
        full
        class="search"
        :value="search"
        @input="onResidentSearch"
        placeholder="Rechercher un résident"
      />

      <div
        v-if="!viewResidents.length"
        class="no-results"
        >
        Aucun résident n’est enregistré à ce nom.
        Vous pouvez contacter l'équipe de l’Ehpad.
    </div>

      <div
        v-else
        class="residents-list"
        >
        <DynamicRow
          :columns="columns"
        >
          <template v-slot:name>
            <div
              class="residents-column residents-name-column"
              @click="onColumnClick('identity')"
            >
              <div class="column-label">Nom et Prénom</div>
              <i class="vs-icon notranslate icon-scale material-icons column-sort-icon">{{ getColumnSortIcon('identity') }}</i>
            </div>
          </template>
          <template v-slot:actions>
            <div
              class="residents-column residents-actions-column"
              @click="onColumnClick('suggestion')"
            >
              <div class="column-label">Suggestions</div>
              <i class="vs-icon notranslate icon-scale material-icons column-sort-icon">{{ getColumnSortIcon('suggestion') }}</i>
            </div>
          </template>
        </DynamicRow>

        <HCard
          class="resident-card"
          hoverZoom
          type="free"
          v-for="(resident, residentIndex) in viewResidents"
          :key="residentIndex"
          @click="onResidentClick(resident)"
        >
          <DynamicRow
            :columns="columns"
          >
            <template v-slot:name>
              <div class="resident-name">
                {{ getResidentCivility(resident) }} {{ getResidentName(resident) }}
              </div>
              <div v-if="resident.roomNumber" class="resident-room">
                Chambre {{ resident.roomNumber }}
              </div>
            </template>
            <template v-slot:actions>
                <HBtn
                    v-if="canEmitSuggestions"
                    :disabled="isSuggestionDisabled(resident)"
                    :title="getResidentSuggestionLabel(resident)"
                    @click.stop="openSuggestionDialog(resident)"
                    block
                >
                    <div
                        v-if="resident.openedSuggestionCount > 0"
                        class="suggestion-count"
                    >{{ resident.openedSuggestionCount }}</div>
                    Suggestions
                </HBtn>
                <HBtn
                    v-if="canReceiveSuggestions"
                    :title="getResidentSuggestionLabel(resident)"
                    @click.stop="goToResidentSuggestions(resident)"
                    :disabled="isSuggestionDisabled(resident)"
                    block
                >
                    <div
                        v-if="resident.openedSuggestionCount > 0"
                        class="suggestion-count"
                    >{{ resident.openedSuggestionCount }}</div>
                    Suggestions
                </HBtn>
            </template>
          </DynamicRow>
        </HCard>
      </div>

      <div
        v-if="viewResidents.length > 0 && pageTotal > 1"
        class="pagination"
      >
        <HPagination
            :value="pageCurrent"
            :length="pageTotal"
            :total-visible="residentPerPage"
            circle
            @input="onPageUpdate"
        />
      </div>

    </div>

    <hx-table
        v-else
        ref="table"
        :data="residents"
        :max-items="itemsPerPage"
        :searchPredicate="searchResidents"
        :sortPredicate="sortResidents"
        class="resident-table"
        noDataText="Aucun résident n’est enregistré à ce nom. Vous pouvez contacter l'équipe de l’Ehpad."
        pagination
        search
        searchPlaceholderText="Rechercher un résident"
        v-on:selected="onResidentClick"
    >
      <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
            v-if="$aclChecker.some($acl, ['isEhpadManager', 'isHappytalSupport', 'isHappytalEmployee', 'isEhpadEmployee'])"
            class="flex flex-wrap-reverse items-center data-list-btn-container"
        >

          <HBtn styleType="secondary" size="large" icon="PlusIcon" @click="onAddResidentBtnClick">Nouveau</HBtn>
        </div>
      </div>
      <template slot="thead">
        <hx-th sort-key="civility">Civilité</hx-th>
        <hx-th sort-key="identity">Nom et Prénom</hx-th>
        <hx-th sort-key="room">Chambre</hx-th>
        <hx-th v-if="isTutorEnabled()" sort-key="tutorType">Type Référent</hx-th>
        <hx-th v-if="isTutorEnabled()" sort-key="tutorIdentity">Nom et Prénom Référent</hx-th>
        <hx-th v-if="isTutorEnabled()" sort-key="tutorEnrollmentStatus">Référent actif</hx-th>
        <hx-th
          v-if="canEmitSuggestions || canReceiveSuggestions"
          sort-key="openedSuggestionCount"
          :style="{ width: '180px' }"
          class="action-col"
        >Actions</hx-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
        <vs-tr
            v-for="(resident, residentIndex) in data"
            :key="residentIndex"
            :data="resident"
            data-cy="resident-row"
        >
          <vs-td>
            <div class="rotw-w-full">
              <p class="resident-civility tw-font-bold truncate">{{ getResidentCivility(resident) }}</p>
            </div>
          </vs-td>
          <vs-td>
            <div class="rotw-w-full">
              <p class="resident-name tw-font-bold truncate">{{ getResidentName(resident) }}</p>
            </div>
          </vs-td>
          <vs-td>
            <div class="rotw-w-full">
              <p class="resident-room">{{ resident.roomNumber }}</p>
            </div>
          </vs-td>
          <vs-td v-if="isTutorEnabled()">
            <div class="rotw-w-full">
              <p class="resident-tutor">{{ getTutorTypeLabel(resident) }}</p>
            </div>
          </vs-td>
          <vs-td v-if="isTutorEnabled()">
            <div class="rotw-w-full">
              <p class="resident-tutor">{{ getTutorName(resident)}}</p>
            </div>
          </vs-td>
          <vs-td v-if="isTutorEnabled()">
            <div class="rotw-w-full text-center">
              <HIcon
                v-if="resident.userInChargeOfResident !== null"
                :icon-name="getTutorEnrollmentStatus(resident) ? 'XIcon' : 'CheckIcon'"
                :class="['white--text', getTutorEnrollmentStatus(resident) ? 'error' : 'success']"
                size="20"
                strokeWidth="2"
                circle
              />
              <div v-else>-</div>
            </div>
          </vs-td>
          <vs-td v-if="canEmitSuggestions || canReceiveSuggestions">
            <div class="row-full d-flex align-center">
                <ConditionalTooltip
                    :enabled="isSuggestionDisabled(resident)"
                    color="gray"
                >
                    <template #activator>
                        <HBtn
                            v-if="canEmitSuggestions"
                            :disabled="isSuggestionDisabled(resident)"
                            :title="getResidentSuggestionLabel(resident)"
                            @click.stop="openSuggestionDialog(resident)"
                            block
                        >
                            <HIcon
                                v-if="resident.openedSuggestionCount === 0"
                                icon-name="MessageIcon"
                            />
                            <div
                                v-else
                                class="suggestion-count"
                            >{{ resident.openedSuggestionCount }}</div>
                            Suggestions
                        </HBtn>
                    </template>
                    <template #tooltip>{{ disabledSuggestionLabel }}</template>
                </ConditionalTooltip>
                <ConditionalTooltip
                    :enabled="isSuggestionDisabled(resident)"
                    color="gray"
                >
                    <template #activator>
                        <HBtn
                            v-if="canReceiveSuggestions"
                            :disabled="isSuggestionDisabled(resident)"
                            :title="getResidentSuggestionLabel(resident)"
                            @click.stop="goToResidentSuggestions(resident)"
                            block
                        >
                            <HIcon
                                v-if="resident.openedSuggestionCount === 0"
                                icon-name="MessageIcon"
                            />
                            <div
                                v-else
                                class="suggestion-count"
                            >{{ resident.openedSuggestionCount }}</div>
                            Suggestions
                        </HBtn>
                    </template>
                    <template #tooltip>{{ disabledSuggestionLabel }}</template>
                </ConditionalTooltip>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </hx-table>

    <HPopin
        v-if="canEmitSuggestions"
        v-model="suggestionFormOpen"
        :width="800"
        @input="closeSuggestionDialog"
    >
      <template #header>
          Suggestion pour {{ getResidentName(suggestionFormResident) }}
      </template>
      <div v-if="suggestionFormStep === 0" class="suggestion-form-step-0 pa-6">
        <div class="suggestion-form-choice">
          Connaissez-vous précisement<br>
          le produit ou service souhaité par le résident ?
        </div>
        <div class="suggestion-form-buttons">
          <HBtn style-type="secondary" class="mx-2" @click="suggestionFormStep = 1">Non, je souhaite faire une suggestion libre</HBtn>
          <HBtn style-type="secondary" class="mx-2" @click="goToResidentCatalog(suggestionFormResident)">Oui, emmenez moi sur le catalogue</HBtn>
        </div>
      </div>
      <div v-if="suggestionFormStep === 1" class="suggestion-form-step-1">
        <SuggestionCreationForm ref="suggestionCreationForm" @create="createNewOpenSugggestion" />
      </div>
    </HPopin>

    <SuggestionWarningPopin
        v-model="suggestionWarningOpen"
        @profile="goToResidentProfile()"
    />
  </div>
</template>

<script>
import Roles from '@/constants/roles'
import Routes from '@/constants/routes'
import {normalizeText} from '@/modules/strings.js'
import {getGenderLabel} from '@/modules/labels'
import {
  HBtn,
  HIcon,
  HPopin,
  HCard,
  HSearchField,
  HPagination
} from '@happytal/bo-ui-library'
import SuggestionCreationForm from '@/components/SuggestionCreationForm'
import SuggestionWarningPopin from '@/components/popins/SuggestionWarningPopin'
import DynamicRow from '@/components/DynamicRow'
import ConditionalTooltip from '@/components/ConditionalTooltip'

export default {
  components: {
    HBtn,
    HIcon,
    SuggestionCreationForm,
    SuggestionWarningPopin,
    HPopin,
    HSearchField,
    HPagination,
    HCard,
    DynamicRow,
    ConditionalTooltip
  },
  data () {
    return {
      selected: {},
      itemsPerPage: 6,
      addNewDataSidebar: false,
      sidebarData: {},
      loaded: false,
      suggestionFormOpen: false,
      suggestionWarningOpen: false,
      suggestionFormResident: null,
      suggestionFormStep: 0,
      search: null,
      pageCurrent: 1,
      residentPerPage: 6,
      pageTotal: 0,
      sortColumn: null,
      sortType: null,
      columns: [
        { slot: 'name', cols: 6 },
        { slot: 'actions', cols: 6, visible: () => this.canEmitSuggestions || this.canReceiveSuggestions }
      ],
      viewResidents: [],
      residentSelected: null
    }
  },
  computed: {
    residents () {
      return this.sortResidentsByDefault(this.$store.state.dataResidents.residents, 'desc')
    },
    canEmitSuggestions () {
        return this.$aclChecker.some(this.$acl, [
            'canEmitSuggestions',
        ])
    },
    canReceiveSuggestions () {
        return this.$aclChecker.some(this.$acl, [
            'canReceiveSuggestions',
        ])
    },
    disabledSuggestionLabel () {
        return `Le référent ne souhaite plus être contacté pour ce résident.`
    }
  },

  methods: {
    isStatusEnabled() {
      switch (this.getUserRole()) {
        case Roles.Tutor:
        case Roles.Family:
        case Roles.EhpadManager:
        case Roles.EhpadEmployee:
        case Roles.HappytalEmployee:
        case Roles.HappytalSupport:
          return true
        default:
          return false
      }
    },
    isTutorEnabled() {
      switch (this.getUserRole()) {
        case Roles.Tutor:
        case Roles.Family:
          return false
        default:
          return true
      }
    },
    getUserRole() {
      return _.get(this.$store, 'state.dataUser.user.role', '')
    },
    getResidentCivility(resident) {
      return getGenderLabel(resident.civility)
    },
    getResidentName(resident) {
      if (!resident) {
        return ''
      }
      return this.$store.getters['dataResidents/getResidentName'](resident.id, false)
    },
    getTutorTypeLabel(resident) {
      var tutorId = resident?.userInChargeOfResident?.id ?? null
      if (!tutorId) {
        return '-'
      }
      else {
        const tutor = this.getTutorById(tutorId)
        switch (tutor?.type) {
          case 'Tutor' :
            return 'Tuteur'
          case 'Family':
            return 'Proche'
          default:
            return '-'
        }
      }
    },
    getTutorName(resident) {
      var tutorId = resident?.userInChargeOfResident?.id ?? null
      if (!tutorId) {
        return '-'
      }
      else return this.$store.getters['dataTutors/getTutorName'](tutorId)
    },
    getTutorEnrollmentStatus(resident) {
      var tutorId = resident?.userInChargeOfResident?.id ?? null
      if (!tutorId) {
        return '-'
      }
      else {
        const tutor = this.getTutorById(tutorId)
        return tutor?.firstVisit
      }
    },
    getTutorById(tutorId) {
      return this.$store.getters['dataTutors/getTutorById'](tutorId)
    },
    getUserId() {
      return this.$store.getters['dataUser/getUserId']()
    },
    getInstitutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    getPaymentStatusColor(resident) {
      if (resident.stripePaymentMethod) {
        return 'success'
      }
      return 'danger'
    },
    getPaymentStatusLabel(resident) {
      if (resident.stripePaymentMethod) {
        return 'Renseigné'
      }
      return 'Non renseigné'
    },
    onResidentSearch(search) {
      this.search = search
      this.pageCurrent = 1
      this.updateResidentList()
    },
    onPageUpdate(page) {
      this.pageCurrent = page
      this.updateResidentList()
    },
    getColumnSortIcon(name) {
      if (this.sortColumn != name) {
        return 'unfold_more'
      }
      if (this.sortType == 'asc') {
        return 'expand_less'
      }
      return 'expand_more'
    },
    onColumnClick(name) {
      if (!this.sortType) {
        this.sortColumn = name
        this.sortType = 'asc'
      }
      else if (this.sortType == 'asc') {
        this.sortType = 'desc'
      }
      else if (this.sortType == 'desc') {
        this.sortColumn = null
        this.sortType = null
      }
      this.updateResidentList()
    },
    onAddResidentBtnClick(e) {
      this.$router.push({
        name: Routes.NewResident
      })
    },
    onResidentClick(resident) {
      this.$router.push({
        name: Routes.ResidentDashboard,
        params: {
          resident_id: resident.id
        }
      })
    },
    sortResidentsByDefault(residents, type) {
      return _.orderBy(residents, ['isNew', 'lastName', 'firstName'], [type])
    },
    sortResidents(key, type, residents, defSortFunc) {
      switch (key) {
        case 'status':
          return _.sortBy(residents, (resident) => {
            const score = resident.hasShoppingListFilled ? 1 : 2
            return type == 'desc' ? -score : score
          })
        case 'identity':
          return _.orderBy(residents, ['lastName', 'firstName'], [type])
        case 'room':
          return _.orderBy(residents, ['roomNumber'], [type])
        case 'tutorType':
          return _.orderBy(residents, [(resident) => {
            return this.getTutorTypeLabel(resident)
          }], [type])
        case 'tutorIdentity':
          return _.orderBy(residents, [(resident) => {
            return this.getTutorName(resident)
          }], [type])
        case 'tutorEnrollmentStatus':
          return _.sortBy(residents, (resident) => {
            const score =  this.getTutorEnrollmentStatus(resident) == '-'  ? 0 : this.getTutorEnrollmentStatus(resident)  ? 1 : 2
            return type == 'desc' ? -score : score
          })
        case 'suggestion':
          return _.orderBy(residents, ['openedSuggestionCount'], [type])
        default:
          return defSortFunc(residents)
      }
    },
    searchResidents(residents, text, defSearchFunc) {
      if (!text) {
        return residents
      }
      const search = normalizeText(text)
      return _.filter(residents, (resident) => {
        const firstName = normalizeText(resident.firstName)
        if (firstName.indexOf(search) > -1) {
          return true
        }
        const lastName = normalizeText(resident.lastName)
        if (lastName.indexOf(search) > -1) {
          return true
        }
        const room = normalizeText(resident.roomNumber)
        if (room.indexOf(search) > -1) {
          return true
        }
        const tutor = resident.userInChargeOfResident
        const tutorStore = tutor ? this.getTutorById(tutor.id) : null
        const tutorFirstName = tutorStore ? normalizeText(tutorStore.firstName) : null
        if (tutorFirstName && tutorFirstName.indexOf(search) > -1) {
          return true
        }
        const tutorLastName = tutorStore ? normalizeText(tutorStore.lastName) : null
        if (tutorLastName && tutorLastName.indexOf(search) > -1) {
          return true
        }
        return false
      })
    },
    updateResidentList () {
      const sourceResidents = this.$store.state.dataResidents.residents
      let results = this.sortResidentsByDefault(sourceResidents, 'desc')
      if (this.search) {
        results = this.searchResidents(results, this.search)
      }
      if (this.sortColumn) {
        results = this.sortResidents(this.sortColumn, this.sortType, results)
      }
      this.pageTotal = Math.ceil(results.length / this.residentPerPage)
      if (this.pageTotal > 0) {
        const offset = Math.max(this.pageCurrent - 1, 0) * this.residentPerPage
        results = results.slice(offset, offset + this.residentPerPage)
      }
      this.viewResidents = results
    },
    isSuggestionDisabled (resident) {
        return !_.get(resident, 'enableSuggestion', false)
    },
    getResidentSuggestionLabel (resident) {
        return this.isSuggestionDisabled(resident) ? `Le référent ne souhaite plus être contacté pour ce résident` : null
    },
    getResidentTutor (resident) {
        return _.get(resident, 'userInChargeOfResident', null)
    },
    isResidentHasTutor (resident) {
        return this.getResidentTutor(resident) != null
    },
    showSuggestionWarning (visible) {
        this.suggestionWarningOpen = visible
    },
    showResidentSuggestionWarning (resident) {
        this.residentSelected = resident
        this.showSuggestionWarning(true)
    },
    isEhpadUser () {
        return this.$aclChecker.some(this.$acl, [
            'isEhpadEmployee',
            'isEhpadManager'
        ])
    },
    goToResidentCatalog(resident) {
      this.$router.push({
          name: Routes.ResidentCatalog,
          params: {
              resident_id: resident.id
          }
      })
    },
    openSuggestionDialog (resident) {
      if (this.isEhpadUser() && !this.isResidentHasTutor(resident)) {
          this.showResidentSuggestionWarning(resident)
          return
      }
      this.suggestionFormOpen = true
      this.suggestionFormResident = resident
      this.suggestionFormStep = 0
    },
    goToResidentSuggestions(resident) {
        if (this.isEhpadUser() && !this.isResidentHasTutor(resident)) {
            this.showResidentSuggestionWarning(resident)
            return
        }
        this.$router.push({
            name: Routes.ResidentSuggestions,
            params: {
                resident_id: resident.id,
            }
        })
    },
    goToResidentProfile() {
        this.$router.push({
            name: Routes.ResidentProfile,
            params: {
                resident_id: this.residentSelected.id,
            }
        })
    },
    showInfoToast(text) {
        this.$toasted.show(text, {
            theme: 'hx-toasted',
            position: 'hx-bottom-left',
            duration: 5000,
            action: [
                {
                    text: 'Fermer',
                    onClick: (e, to) => {
                        to.goAway(0)
                    }
                }
            ]
        })
    },
    resetSuggestionDialog() {
      this.$refs.suggestionCreationForm.reset()
      const sto = setTimeout( () => {
          this.suggestionFormStep = 0
          this.suggestionFormResident = null
          clearTimeout(sto)
      }, 250)

    },
    closeSuggestionDialog() {
      this.suggestionFormOpen = false
      this.resetSuggestionDialog()
    },
    async createNewOpenSugggestion(shortDescription, description, creatorName, continueAfterCreation) {
        await this.$store.dispatch('dataSuggestions/createOpenSuggestion', {
            residentId: this.suggestionFormResident.id,
            creatorName,
            institutionId: this.getInstitutionId(),
            shortDescription,
            description,
        })
        if (continueAfterCreation) {
            this.$refs.suggestionCreationForm.reset()
        } else {
            this.closeSuggestionDialog()
        }
        this.showInfoToast('La suggestion a bien été envoyée')
    },
  },
  async mounted() {
    const isResidentsRegistered = this.$store.state.dataResidents.isRegistered
    const isTutorsRegistered = this.$store.state.dataTutors.isRegistered
    this.loaded = false

    try {
      if (!isResidentsRegistered) {
        await this.$store.dispatch('dataResidents/getResidents', {
          userId: this.getUserId(),
          institutionId: this.getInstitutionId()
        })
      }

      this.updateResidentList()

      if ([Roles.Tutor, Roles.Family].includes(this.getUserRole()) && this.$store.state.dataResidents.residents.length === 1) {
        const resident = this.$store.state.dataResidents.residents[0]
        this.$router.push({
          name: Routes.ResidentDashboard,
          params: {
            resident_id: resident.id
          }
        })
      }

      if (!isTutorsRegistered) {
        await this.$store.dispatch('dataTutors/getTutors', {
          institutionId: this.getInstitutionId()
        })
      }

    } catch (error) {
      console.error(error)
    }
    finally {
      this.loaded = true
    }
  }
}
</script>

<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';

.vs-dialog-cancel {
  display: none;
}

#residents-view {

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #2C2C2C;
    }
  }

  .search {
    margin: 14px 0px 0px 0px;
  }
  .no-results {
    margin: 30px 5px 0px 5px;
    font-size: 14px;
    line-height: 18px;
    color: black;
  }
  .residents-list {
    margin: 34px 0px 18px 0px;

    .residents-column {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px 10px 0px;
      cursor: pointer;

      .column-label {
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        text-transform: uppercase;
        color: black;
      }
      .column-sort-icon {
        margin: 0px 0px 0px 5px;
      }
    }
    .residents-name-column {

    }
    .residents-actions-column {

    }

    .resident-card {
      margin: 0px 0px 8px 0px;
      padding: 10px 10px 10px 10px;
      border-left: 8px solid rgba(var(--vs-primary), 1);
      cursor: pointer;

      .resident-name {
        margin: 0px 10px 0px 0px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
      }
      .resident-room {
        font-size: 10px;
        line-height: 14px;
        color: #000000;
      }
    }
  }
  .pagination {
    width: 100vw;
    position: fixed;
    z-index: 5;
    left: 0;
    bottom: 0;
    background: white;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.1);
  }

  .vs-table--search {
    max-width: 300px !important;
  }

  .vs-table--search-input {
    width: 300px !important;
  }

  .vs-con-table {

    .con-vs-pagination {
      text-align: center;
      margin: 0 auto 0 auto;
      display: block;
      width: 100% !important;
    }

    .vs-lg-6 {
      width: 100% !important
    }

    .rotw-w-full {
      width: 100%;
      height: 29px;
      padding: 3px 0px 0px 0px;
    }

    .action-col { // TODO : Refacto this, use utilitary class to disbale click
      &.disable-click {
        pointer-events: none;
      }
    }

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .resident-civility {

    }

    .resident-name {
      max-width: 23rem;
    }

    .resident-status {

    }

    .resident-status-new {
      color: rgba(var(--vs-danger), 1);

      .status-bullet {
        display: inline-block;
        margin: 0px 15px 0px 0px;
        background: rgba(var(--vs-danger), 1);
        width: 15px;
        height: 15px;
        border-radius: 15px;
      }

      .status-label {

      }
    }

    .payment-status {
      user-select: none;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.suggestion-form-step-0 {
  .suggestion-form-choice {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  .suggestion-form-buttons {
    display: flex;
    justify-content: center;

    .v-btn {
      border-color: #17D3C3;
      color: #17D3C3;
      font-weight: bold;
    }
  }
}

.suggestion-form-step-1 {
  .v-btn {
    border-color: #17D3C3;
    color: #17D3C3;

    &.primary {
      background-color: #17D3C3;
      color: white;
    }
  }
}

.suggestion-count { // TODO: Refacto this, use component instead because there is this element in ResidentMenu too
  background: #FFFFFF;
  border: 1px solid #EA5455;
  border-radius: 50%;
  color: #EA5455;
  font-weight: bold;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}
</style>
