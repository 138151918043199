
















































import Vue from 'vue'

import {
  HForm,
  HFormField,
  HInput,
  HTextarea,
  HBtn,
  HIcon,
  HPopover,
} from '@happytal/bo-ui-library'

export default Vue.extend({
  components: {
    HForm,
    HFormField,
    HInput,
    HTextarea,
    HBtn,
    HIcon,
    HPopover,
  },
  data() {
    return {
      shortDescription: '',
      shortDescriptionErrorText: '',
      description: '',
      creatorName: '',
    }
  },
  computed: {
    isValid(): boolean {
      return !!this.shortDescription
    },
    currentUserName(): string {
      return this.$store.getters['dataUser/getUserDisplayName']
    }
  },
  methods: {
    reset() {
      this.shortDescription = ''
      this.description = ''
      this.creatorName = ''
    },
    isEhpadCollaborator() {
      return this.$aclChecker.some(this.$acl, [
        'isEhpadManager',
        'isEhpadEmployee'
      ])
    },
    createSuggestion(continueAfterCreation: boolean) {
      this.shortDescriptionErrorText = ''
      if (!this.shortDescription) {
        this.shortDescriptionErrorText = 'Ce champ est obligatoire'
        return
      }
      if (this.$gtm.enabled()) {
          this.$gtm.trackEvent({
            event: 'create_suggestion_free'
          })
        }
      this.$emit(
        'create',
        this.shortDescription,
        this.description,
        this.creatorName,
        continueAfterCreation,
      )
      this.reset()
    }
  }
})
